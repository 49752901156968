import React from "react";
import { Pagination } from "react-bootstrap";

const AppPagination = ({ data, onClick }) => {
  return data?.last_page > 1 ? (
    <div className="d-flex justify-content-end">
      <Pagination className="mt-4">
        <Pagination.First
          onClick={() => onClick(1)}
          disabled={!!(data.current_page === 1)}
        />
        <Pagination.Prev
          onClick={() =>
            onClick(
              data.current_page > 1 ? data.current_page - 1 : data.current_page
            )
          }
          disabled={!!(data.current_page === 1)}
        />
        <Pagination.Next
          onClick={() =>
            onClick(
              data.current_page !== data.last_page
                ? data.current_page + 1
                : data.last_page
            )
          }
          disabled={!!(data.current_page === data.last_page)}
        />
        <Pagination.Last
          onClick={() => onClick(data?.last_page ?? 1)}
          disabled={!!(data.current_page === data.last_page)}
        />
      </Pagination>
    </div>
  ) : null;
};

export default AppPagination;
