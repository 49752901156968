import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";

const GuestRequire = () => {
  let { user } = useContext(LoginContext);
  return user ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default GuestRequire;
