import React, { useContext } from "react";
import { AccordionContext, Button, useAccordionButton } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const CollapseCustomButton = ({ eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const handleClick = useAccordionButton(eventKey);
  return (
    <Button
      variant="transparent"
      className="payment-history-collapse-btn"
      onClick={handleClick}
    >
      {activeEventKey === eventKey ? <FaChevronUp /> : <FaChevronDown />}
    </Button>
  );
};

export default CollapseCustomButton;
