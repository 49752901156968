import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { lazy, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import DashboardLayout from "./layout/DashboardLayout";
import GuestRequire from "./helper/GuestRequire";
import AuthRequire from "./helper/AuthRequire";
import StoreRequire from "./helper/StoreRequire";
import { ItemContext } from "./contexts/ItemContenxt";
import { CartContext } from "./contexts/CartContext";
import { PublicItemContenxt } from "./contexts/PublicItemContenxt";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import AuthProvider from "./providers/AuthProvider";
import AdminDashboardLayout from "./module/admin/page/AdminDashboardLayout";
import AdminDashboard from "./module/admin/page/dashboard/AdminDashboard";
import Customers from "./module/admin/page/customers/Customers";
import Invoice from "./module/admin/page/accounts/Invoice";
import SuspenseElelement from "./components/SuspenseElelement/SuspenseElelement";
import Stores from "./pages/stores/Stores";
import DashboardMainPage from "./pages/dashboard/DashboardMainPage";

// lazy load components
const Checkout = lazy(() => import("./pages/checkout/Checkout"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Category = lazy(() => import("./pages/category/Category"));
const Product = lazy(() => import("./pages/product/Product"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const Billing = lazy(() => import("./pages/billing/Billing"));
const BillPreview = lazy(() => import("./pages/bill-preview/BillPreview"));

const LandingPage = lazy(() => import("./pages/landing/LandingPage"));
const Plans = lazy(() => import("./pages/plans/Plans"));
const Search = lazy(() => import("./pages/search/Search"));
const Cart = lazy(() => import("./pages/cart/Cart"));
const StoreDetails = lazy(() => import("./pages/storeDetails/StoreDetails"));
const ProductList = lazy(() => import("./pages/productList/ProductList"));

const Login = lazy(() => import("./pages/auth/login/Login"));
const Register = lazy(() => import("./pages/auth/register/Register"));
const AccountRecovery = lazy(() =>
  import("./pages/auth/AccountRecovery/AccountRecovery")
);
const ResetPassword = lazy(() =>
  import("./pages/auth/ResetPassword/ResetPassword")
);

function App() {
  const [items, setItems] = useState(useContext(ItemContext));
  const [publicItems, setPublicItems] = useState(
    useContext(PublicItemContenxt)
  );
  const [cart, setCart] = useState(useContext(CartContext));

  const getAuthToken = () => {
    let userData = JSON.parse(localStorage.getItem("MABIZ_DIGITAL_STORE_APP"));
    return userData && userData.token ? userData.token : undefined;
  };

  useEffect(() => {
    let cartItems = localStorage.getItem("MABIZ_DIGITAL_STORE_APP_CART");
    cartItems && setCart(JSON.parse(cartItems));
  }, []);

  // setting axios config
  axios.interceptors.request.use(
    (config) => {
      config.baseURL = process.env.REACT_APP_API_BASE_URL;
      config.withCredentials = true;
      config.headers.post["Content-Type"] = "application/json";
      config.headers.post["Accept"] = "application/json";
      config.headers.common["Authorization"] = `Bearer ${getAuthToken()}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <div className="App">
      <AuthProvider>
        <ItemContext.Provider value={{ items, setItems }}>
          <PublicItemContenxt.Provider value={{ publicItems, setPublicItems }}>
            <CartContext.Provider value={{ cart, setCart }}>
              <Router>
                <Routes>
                  <Route element={<DashboardLayout />}>
                    <Route element={<SuspenseElelement />}>
                      <Route path="/" element={<LandingPage />} />
                      <Route path="subscribe" element={<Plans />} />
                      <Route element={<StoreRequire />}>
                        <Route path="search/:storeID" element={<Search />} />
                        <Route path="cart/:storeID" element={<Cart />} />
                        <Route
                          path="store/:storeID"
                          element={<StoreDetails />}
                        />
                        <Route
                          path="products/:storeID/:categoryID"
                          element={<ProductList />}
                        />
                      </Route>

                      <Route element={<GuestRequire />}>
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Register />} />
                        <Route
                          path="account-recovery"
                          element={<AccountRecovery />}
                        />
                        <Route
                          path="reset-password/:token"
                          element={<ResetPassword />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path="*"
                      element={
                        <Container className="mt-4 text-center">
                          <h1>404</h1>
                        </Container>
                      }
                    />
                  </Route>

                  <Route element={<AuthRequire />}>
                    <Route element={<SuspenseElelement />}>
                      <Route path="dashboard" element={<Dashboard />}>
                        <Route index element={<DashboardMainPage />}></Route>
                        <Route path="warehouse" element={<Stores />}></Route>
                        <Route path="inventory" element={<Outlet />}>
                          <Route path="category" element={<Category />}></Route>
                          <Route path="items" element={<Product />}></Route>
                        </Route>
                      </Route>
                    </Route>
                    {/* <Route path="dashboard" element={<SuspenseElelement />}>
                      <Route path="checkout/:plan" element={<Checkout />} />
                      <Route index element={<Dashboard />} />
                      <Route
                        path="categories/:storeID"
                        element={<Category />}
                      />
                      <Route
                        path="products/:storeID/:categoryID"
                        element={<Product />}
                      />
                      <Route path="profile" element={<Profile />} />
                      <Route path="billing">
                        <Route index element={<Billing />} />
                        <Route
                          path="view-invoice/:invoiceNumber"
                          element={<BillPreview />}
                        />
                      </Route>
                    </Route> */}
                    <Route path="admin" element={<AdminDashboardLayout />}>
                      <Route
                        index
                        element={
                          <Navigate to="/admin/dashboard" replace={true} />
                        }
                      />
                      <Route path="dashboard" element={<AdminDashboard />} />
                      <Route path="customers" element={<Customers />} />
                      <Route path="accounts/invoice" element={<Invoice />} />
                    </Route>
                  </Route>
                </Routes>
              </Router>
            </CartContext.Provider>
          </PublicItemContenxt.Provider>
        </ItemContext.Provider>
      </AuthProvider>
      <div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
