import React from "react";
import { Badge, Card, Col, Container, Row, Table } from "react-bootstrap";
import BrandLogo from "../brandLogo/BrandLogo";
import InvoicePrintButton from "./invoice-print-button/InvoicePrintButton";

const InvoicePreview = ({ invoice, enablePrint }) => {
  return (
    <Container
      fluid
      className="d-flex flex-column align-items-center py-4 invoice-container"
    >
      <Card style={{ width: "100%", maxWidth: "1300px" }}>
        <Card.Body>
          <Row className="align-items-center">
            <Col
              sm={7}
              className="d-flex justify-content-center justify-content-sm-start mb-3 mb-sm-0"
            >
              <div style={{ width: "100%", maxWidth: "110px" }}>
                <BrandLogo />
              </div>
            </Col>
            <Col sm={5} className="text-center text-sm-end">
              <h4 className="text-7 mb-0">Invoice</h4>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={6}>
              <p className="small mb-1">
                <strong>Created at:</strong> {invoice.created_at_formatted}
              </p>
              <p className="small mb-0">
                <strong>Paid at:</strong>{" "}
                {invoice.is_paid ? (
                  invoice.paid_at_formatted
                ) : (
                  <Badge bg="danger">Pending</Badge>
                )}
              </p>
            </Col>
            <Col sm={6} className="text-muted text-sm-end small">
              <strong>Invoice No:</strong> {invoice.invoice_number}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={{ span: 6, order: 1 }} className="text-sm-end">
              <strong>Bill from:</strong>
              <address style={{ whiteSpace: "pre-line" }} className="small">
                {invoice.pay_to}
              </address>
            </Col>
            <Col sm={{ span: 6, order: 0 }}>
              <strong>Bill to:</strong>
              <address style={{ whiteSpace: "pre-line" }} className="small">
                {invoice.invoiced_to}
              </address>
            </Col>
          </Row>

          <Card>
            <Card.Body className="p-0">
              <Table className="mb-0" responsive>
                <thead className="card-header">
                  <tr>
                    <td>
                      <strong>Service</strong>
                    </td>
                    <td>
                      <strong>Description</strong>
                    </td>
                    <td className="text-center">
                      <strong>Quantity (Period)</strong>
                    </td>
                    <td className="text-center">
                      <strong>Price</strong>
                    </td>
                    <td className="text-center">
                      <strong>Discount</strong>
                    </td>
                    <td className="text-end">
                      <strong>Subtotal</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="small">
                    <td>{invoice.service}</td>
                    <td>
                      <p className="mb-0" style={{ whiteSpace: "pre-line" }}>
                        {invoice.description ?? "-"}
                      </p>
                    </td>
                    <td className="text-center ">
                      {invoice.quantity}
                      {invoice.period ? (
                        <span className="ms-1">({invoice.period})</span>
                      ) : null}
                    </td>
                    <td className="text-center ">
                      {(invoice.currency_code ?? "").concat(" ", invoice.price)}
                    </td>
                    <td className="text-center ">
                      {(invoice.currency_code ?? "").concat(
                        " ",
                        invoice.discount
                      )}
                    </td>
                    <td className="text-end ">
                      {invoice.subtotal
                        ? (invoice.currency_code ?? "").concat(
                            " ",
                            invoice.subtotal
                          )
                        : "-"}
                    </td>
                  </tr>
                </tbody>
                <tfoot className="card-footer">
                  {invoice.tax ? (
                    <tr>
                      <td colSpan="5" className="text-end">
                        <strong>Tax:</strong>
                      </td>
                      <td className="text-end">
                        $7.02 <small>(18%)</small>
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td colSpan="5" className="text-end border-bottom-0">
                      <strong>Total:</strong>
                    </td>
                    <td className="text-end border-bottom-0">
                      {invoice.subtotal
                        ? (invoice.currency_code ?? "").concat(
                            " ",
                            invoice.subtotal
                          )
                        : "-"}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Card.Body>
          </Card>
        </Card.Body>
        {enablePrint ? (
          <Card.Footer className="text-center bg-white border-top-0">
            <InvoicePrintButton invoice_number={invoice.invoice_number} />
          </Card.Footer>
        ) : null}
      </Card>
    </Container>
  );
};

export default InvoicePreview;
