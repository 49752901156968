import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import LoadingUI from "../LoadingUI/LoadingUI";

export default function SuspenseElelement() {
  return (
    <Suspense fallback={<LoadingUI />}>
      <Outlet />
    </Suspense>
  );
}
