import React from "react";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AppNavLinkDropdown from "../NavLinkDropDown/NavLinkDropDown";

const AppNavLink = (props) => {
  return (
    <Nav.Item as="li" className="w-100">
      <OverlayTrigger
        {...(!props.shrink ? { show: false } : null)}
        placement="right"
        overlay={
          <Tooltip id={`nav-lnk-${props.idPrefix ?? ""}${props.index}`}>
            {props.navItem.name}
          </Tooltip>
        }
      >
        {props.navItem.to ? (
          <Nav.Link
            to={props.navItem.to}
            as={NavLink}
            className="d-flex flex-row align-items-center"
          >
            {props.navItem.Icon ? <props.navItem.Icon /> : null}
            <span className="ms-1">{props.navItem.name}</span>
          </Nav.Link>
        ) : props.navItem.childrens ? (
          <AppNavLinkDropdown navItem={props.navItem} shrink={props.shrink} />
        ) : (
          <></>
        )}
      </OverlayTrigger>
    </Nav.Item>
  );
};

export default AppNavLink;
