import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const Search = () => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="cart-tooltip">Search products</Tooltip>}
    >
      <Button
        variant="dark"
        style={{
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          boxShadow: "none",
        }}
      >
        <FontAwesomeIcon icon={faSearch} />
      </Button>
    </OverlayTrigger>
  );
};

export default Search;
