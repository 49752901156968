import React, { useState, useEffect } from "react";
import { Collapse, Nav } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import AppNavLink from "../AppNavLink/AppNavLink";

const AppNavLinkDropdown = ({ navItem, shrink }) => {
  let location = useLocation();
  const [open, setIsOpen] = useState(false);
  const handleShow = () => setIsOpen(!open);

  useEffect(() => {
    setIsOpen(
      navItem.matchPath && location.pathname.includes(navItem.matchPath)
        ? true
        : false
    );

    return () => {
      setIsOpen(false);
    };
  }, [location.pathname, navItem.matchPath]);

  return (
    <>
      <Nav.Link
        as={"a"}
        className="d-flex flex-row align-items-center mb-0"
        onClick={handleShow}
        role="button"
      >
        <navItem.Icon />
        <span className="mx-1">{navItem.name}</span>
        {open ? (
          <FaChevronUp className="ms-auto text-muted" size={12} />
        ) : (
          <FaChevronDown className="ms-auto text-muted" size={12} />
        )}
      </Nav.Link>
      {navItem.childrens ? (
        <Collapse in={open}>
          <ul className="app-nav-dropdown list-unstyled">
            {navItem.childrens.map((navItem, index) => (
              <AppNavLink
                key={index}
                index={index}
                idPrefix="sub-lvl-1-"
                navItem={navItem}
                shrink={shrink}
              />
            ))}
          </ul>
        </Collapse>
      ) : null}
    </>
  );
};

export default AppNavLinkDropdown;
