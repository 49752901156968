import React from "react";

export default function LoadingUI({ message }) {
  return (
    <div className="m-auto">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="mb-3">
          <img
            src="/favicon.ico"
            alt="favicon"
            className="w-100 rounded"
            style={{ maxWidth: 35 }}
          />
        </div>
        <small className="fw-bold text-muted">{message ?? "Loading ..."}</small>
      </div>
    </div>
  );
}
