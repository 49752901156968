import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { Alert, Badge, Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ItemContext } from "../../contexts/ItemContenxt";

const StoreForm = ({ show, handleModal, editItem, deleteItem }) => {
  const method =
    Object.keys(editItem).length > 0 ? "put" : deleteItem ? "delete" : "post";
  const { items, setItems } = useContext(ItemContext);
  const imageinput = useRef(null);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    image: null,
    name: "",
    slug: "",
    contact_number: "",
    address: "",
    other_notes: "",
  });
  const [errors, setErrors] = useState({});
  const handleFieldChange = (e) => {
    let value = null;
    if (e.target.type === "file" && e.target.files.length > 0) {
      value = e.target.files[0];
    } else if (e.target.name === "contact_number") {
      value = e.target.value.match(/^\+?\d*/g);
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleForm = (e) => {
    e.preventDefault();
    setSaving(true);
    setErrors({});
    let formDataWithFile = new FormData();

    for (var key in formData) {
      if (formData[key]) {
        formDataWithFile.append(key, formData[key]);
      }
    }

    method === "put" && formDataWithFile.append("_method", "put");
    axios
      .get("/sanctum/csrf-cookie")
      .then((response) => {
        let url =
          "/api/store" +
          (method === "put"
            ? "/" + editItem.id
            : method === "delete"
            ? "/" + deleteItem
            : "");

        axios({
          method: method === "delete" ? method : "post",
          url: url,
          data: formDataWithFile,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            setSaving(false);
            const { data } = response;
            if (data) {
              let newData = null;
              if (method === "post" && data.data) {
                newData = [data.data, ...items.stores];
              } else if (method === "put" && data.data) {
                newData = items.stores.map((item) => {
                  return item.id === editItem.id ? data.data : item;
                });
              } else if (method === "delete" && data.message) {
                newData = items.stores.filter((item) => item.id !== deleteItem);
              }

              // alert message
              if (data.message) {
                toast.success(data.message);
              }

              if (newData) {
                handleModal();
                setItems({ ...items, stores: newData });
              }
            }
            imageinput.current.value = "";
            method === "post" &&
              setFormData({
                image: null,
                name: "",
                contact_number: "",
                address: "",
                other_notes: "",
              });
          })
          .catch((error) => {
            setSaving(false);
            if (error?.response?.data?.errors) {
              setErrors(error.response.data.errors);
            } else if (error?.response?.data?.message) {
              toast.error(error.response.data.message);
            } else {
              toast.error("Something went wrong!");
            }
          });
      })
      .catch((error) => {
        setSaving(false);
        toast.error("Something went wrong!");
      });
  };

  useEffect(() => {
    const editItemData = (({
      name,
      slug,
      contact_number,
      address,
      other_notes,
    }) => ({
      name,
      slug,
      contact_number,
      address,
      other_notes,
    }))(editItem);
    setFormData(editItemData);
  }, [editItem]);

  return (
    <Modal show={show} onHide={handleModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {method === "put"
            ? "Edit store details"
            : method === "delete"
            ? "Delete store"
            : "Add new store"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleForm}>
          {method !== "delete" ? (
            <>
              <Form.Group className="mb-3" controlId="formBasicStoreImage">
                <Form.Label>
                  Image <Badge>450 * 225</Badge>
                </Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  placeholder="Store Image"
                  onChange={handleFieldChange}
                  accept=".jpg,.jpeg,.png,.gif"
                  ref={imageinput}
                  isInvalid={!!errors.image}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.image}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicSlug">
                <Form.Label>Slug</Form.Label>
                <Form.Control
                  type="text"
                  name="slug"
                  placeholder="URL Slug"
                  required
                  value={formData.slug}
                  onChange={handleFieldChange}
                  isInvalid={!!errors.slug}
                />
                <Form.Text className="text-muted">
                  It must be unique and contain only letters and hyphens.
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.slug}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicStoreName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                  value={formData.name}
                  onChange={handleFieldChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formBasicStoreContactNumber"
              >
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="tel"
                  name="contact_number"
                  placeholder="Contact Number"
                  required
                  value={formData.contact_number}
                  onChange={handleFieldChange}
                  isInvalid={!!errors.contact_number}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contact_number}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicStoreAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="address"
                  placeholder="Address"
                  rows={3}
                  onChange={handleFieldChange}
                  value={formData.address}
                  required
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicStoreOtherNotes">
                <Form.Label>Other Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  name="other_notes"
                  placeholder="Other Notes"
                  rows={3}
                  onChange={handleFieldChange}
                  value={formData.other_notes}
                  isInvalid={!!errors.other_notes}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.other_notes}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          ) : (
            <Alert variant="danger">
              Are you sure you want to delete this item?
            </Alert>
          )}

          <Form.Group className="d-flex flex-row">
            <Button
              variant={method === "delete" ? "danger" : "primary"}
              type="submit"
              className="flex-fill"
              disabled={
                method !== "delete"
                  ? !formData.slug ||
                    !formData.name ||
                    !formData.contact_number ||
                    !formData.address ||
                    saving
                  : saving
              }
            >
              {method === "put"
                ? !saving
                  ? "Update"
                  : "Updating..."
                : method === "delete"
                ? !saving
                  ? "Delete"
                  : "Deleting..."
                : !saving
                ? "Save"
                : "Saving..."}
            </Button>
            {method === "delete" && (
              <Button
                variant="success"
                className="ms-1 flex-fill"
                onClick={handleModal}
              >
                Cancel
              </Button>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default StoreForm;
