import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const SubscriptionExpiredAlert = () => {
  return (
    <Modal show={true} backdrop="static" keyboard={false} centered>
      <Modal.Header style={{ backgroundColor: "#662d91" }}>
        <Modal.Title className="text-white">Subscription Expired</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Subscription has expired.&nbsp;
        <Link to="/subscribe" style={{ color: "#662d91" }}>
          Click here
        </Link>
        &nbsp;to renew your subscription.&nbsp;
        <Link to="/dashboard/profile" style={{ color: "#662d91" }}>
          Click here
        </Link>
        &nbsp;to know more about your last purchase.
      </Modal.Body>
      <Modal.Footer className="border-0"></Modal.Footer>
    </Modal>
  );
};

export default SubscriptionExpiredAlert;
