import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Badge, Table } from "react-bootstrap";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import AppPagination from "../../../../components/AppPagination/AppPagination";
import CollapseCustomButton from "../../../../components/collapse-custom-button/CollapseCustomButton";
import InvoicePreview from "../../../../components/invoice/InvoicePreview";

const Invoice = () => {
  const [isLoadin, setisLoadin] = useState(true);
  const [invoicesData, setInvocesData] = useState(undefined);
  const getInvoiceList = async (pageNumber) => {
    try {
      setisLoadin(true);
      let data = await axios.get("/api/list-invoices", {
        params: { page: pageNumber },
      });
      if (data?.data?.data) setInvocesData(data.data.data);
      setisLoadin(false);
    } catch (error) {
      setisLoadin(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getInvoiceList();
    return () => {
      setInvocesData(undefined);
      setisLoadin(true);
    };
  }, []);

  return isLoadin ? (
    "loading data..."
  ) : invoicesData?.data ? (
    <Accordion>
      <Table className="small" responsive striped hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Invoice number</th>
            <th>Customer Name</th>
            <th>Service</th>
            <th>Amount</th>
            <th>Discount</th>
            <th>Subtotal</th>
            <th>Paid</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {invoicesData.data.map((data, index) => (
            <Fragment key={index}>
              <tr>
                <td>{(invoicesData?.from ?? 1) + index}</td>
                <td>
                  {data.invoice_number ? (
                    <Badge bg="success">{data.invoice_number}</Badge>
                  ) : (
                    <Badge bg="danger">Free version</Badge>
                  )}
                </td>
                <td>{data?.customer?.name}</td>
                <td>{data.service}</td>
                <td>
                  {data.currency_code} {data.price}
                </td>
                <td>
                  {data.currency_code} {data.discount}
                </td>
                <td>
                  {data.currency_code} {data.subtotal}
                </td>
                <td>
                  {data.is_paid ? (
                    <FaCheckCircle className="text-success" />
                  ) : data.plan !== "basic" ? (
                    <FaTimesCircle className="text-danger" />
                  ) : null}
                </td>
                <td>
                  <CollapseCustomButton eventKey={index} />
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="px-0 py-0">
                  <Accordion.Collapse eventKey={index}>
                    <InvoicePreview invoice={data} enablePrint />
                  </Accordion.Collapse>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>
      <AppPagination data={invoicesData} onClick={getInvoiceList} />
    </Accordion>
  ) : (
    "Something went wrong"
  );
};

export default Invoice;
