import React, { useContext, useEffect, useState } from "react";
import { Alert, Card, Col, Container, Placeholder, Row } from "react-bootstrap";
import { FaStore, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { LoginContext } from "../../../../contexts/LoginContext";
import axios from "axios";

const AdminDashboard = () => {
  let { user } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [dashbaordData, setDashbaordData] = useState(undefined);

  useEffect(() => {
    try {
      setIsLoading(true);
      axios
        .get("/api/dashboard-data")
        .then((response) => {
          if (response?.data?.data) {
            setDashbaordData(response.data.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
    return () => {
      setIsLoading(true);
    };
  }, []);

  return (
    <Container className="px-0">
      <div className="pt-2 pb-4">
        <h1 className="fw-bold m-0">Dashbaord</h1>
        <p className="small text-muted">
          Welcome back, {user.name}&nbsp;&#128075;
        </p>
      </div>
      {!isLoading && !dashbaordData ? (
        <Alert variant="warning">Something went wrong</Alert>
      ) : (
        <Row md={3} sm={1}>
          <Col>
            <Card className="shadow-sm">
              <Card.Header className="bg-white border-bottom-0 pb-0">
                Total Customers
              </Card.Header>
              <Card.Body className="pt-0">
                <p className="h3 fw-bold mb-0 py-3">
                  {isLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder md={4} xs={7} />
                    </Placeholder>
                  ) : (
                    dashbaordData?.["total-customers"] ?? "-"
                  )}
                </p>
                <div className="d-flex flex-row jsutify-content-center">
                  <div className="border rounded px-2 py-1 shadow-sm">
                    <FaUser className="text-success" size={13} />
                  </div>
                  <div
                    className="ms-2"
                    style={{
                      fontSize: "0.7rem",
                    }}
                  >
                    <p className="m-0">Customer</p>
                    <Link to={"/admin/customers"} className="text-muted">
                      List all customers
                    </Link>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="shadow-sm">
              <Card.Header className="bg-white border-bottom-0 pb-0">
                Total Stores
              </Card.Header>
              <Card.Body className="pt-0">
                <p className="h3 fw-bold mb-0 py-3">
                  {isLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder md={4} xs={7} />
                    </Placeholder>
                  ) : (
                    dashbaordData?.["total-stores"] ?? "-"
                  )}
                </p>
                <div className="d-flex flex-row jsutify-content-center">
                  <div className="border rounded px-2 py-1 shadow-sm">
                    <FaStore className="text-primary" size={13} />
                  </div>
                  <div
                    className="ms-2"
                    style={{
                      fontSize: "0.7rem",
                    }}
                  >
                    <p className="m-0">Store</p>
                    <Link to={"/admin/dashbaord"} className="text-muted">
                      List all customers
                    </Link>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AdminDashboard;
