import React, { useContext } from "react";
import { LoginContext } from "../../contexts/LoginContext";
import { Card, Col, Row } from "react-bootstrap";
import { FaBox, FaCubes, FaWarehouse } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function DashboardMainPage() {
  const { user } = useContext(LoginContext);
  return (
    <>
      <div>
        <p className="h5">Dashboard</p>
        <p className="text-muted small">
          Welcome back, <b className="text-dark">{user?.name} &#128075;</b>
        </p>
      </div>

      <Row className="mt-4" xs={1} lg={3}>
        <Col className="d-flex mb-3">
          <Card className="border-0 box-shadow flex-fill rounded">
            <Link to="/dashboard/warehouse">
              <Card.Body>
                <div className="d-flex flex-row gap-2 align-items-center mb-2">
                  <div className="bg-info px-2 py-1 rounded text-light">
                    <FaWarehouse className="h6 m-0" />
                  </div>
                  <div>
                    <p className="m-0 fw-bold h6 m-0 text-dark">Warehouse</p>
                    <small className="text-muted">
                      Warehouse basic details
                    </small>
                  </div>
                </div>

                <p className="small text-muted fw-bold h1 mt-3">
                  500 Results found
                </p>
              </Card.Body>
            </Link>
          </Card>
        </Col>
        <Col className="d-flex mb-3">
          <Card className="border-0 box-shadow flex-fill rounded">
            <Link to="/dashboard/inventory/category">
              <Card.Body>
                <div className="d-flex flex-row gap-2 align-items-center mb-2">
                  <div className="bg-danger px-2 py-1 rounded text-light">
                    <FaCubes className="h6 m-0" />
                  </div>
                  <div>
                    <p className="m-0 fw-bold h6 m-0 text-dark">Category</p>
                    <small className="text-muted">Category basic details</small>
                  </div>
                </div>

                <p className="small text-muted fw-bold h1 mt-3">
                  500 Results found
                </p>
              </Card.Body>
            </Link>
          </Card>
        </Col>
        <Col className="d-flex mb-3">
          <Card className="border-0 box-shadow flex-fill rounded">
            <Link to="/dashboard/inventory/items">
              <Card.Body>
                <div className="d-flex flex-row gap-2 align-items-center mb-2">
                  <div className="bg-success px-2 py-1 rounded text-light">
                    <FaBox className="h6 m-0" />
                  </div>
                  <div>
                    <p className="m-0 fw-bold h6 m-0 text-dark">Items</p>
                    <small className="text-muted">Items basic details</small>
                  </div>
                </div>

                <p className="small text-muted fw-bold h1 mt-3">
                  500 Results found
                </p>
              </Card.Body>
            </Link>
          </Card>
        </Col>
      </Row>
    </>
  );
}
