import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const InvoicePrintButton = ({ invoice_number }) => {
  return (
    <Button
      size="sm"
      variant="light"
      className="border text-black-50 shadow-none"
      as={Link}
      to={"/dashboard/billing/view-invoice/" + invoice_number}
    >
      Print / Download
    </Button>
  );
};

export default InvoicePrintButton;
