import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import SubscriptionExpiredAlert from "../components/SubscriptionExpiredAlert/SubscriptionExpiredAlert";

const AuthRequire = () => {
  const location = useLocation();
  let { user } = useContext(LoginContext);
  return !user ? (
    <Navigate to="/login" />
  ) : user.role !== "admin" &&
    !user.package &&
    location.pathname !== "/checkout/pro" ? (
    <Navigate to="/subscribe" />
  ) : user.role !== "admin" &&
    user?.package?.plan_expired &&
    location.pathname !== "/dashboard/profile" ? (
    <SubscriptionExpiredAlert />
  ) : user.role !== "admin" &&
    user.package &&
    user.package.plan !== "basic" &&
    !user.package.is_paid &&
    !location.pathname.includes("/checkout") &&
    !location.pathname.includes("/dashboard/billing/view-invoice") ? (
    <Navigate
      to={`/dashboard/billing/view-invoice/${user.package.invoice_number}`}
    />
  ) : (
    <Outlet />
  );
};

export default AuthRequire;
