import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const ComponentSearch = ({ value, onChange }) => {
  return (
    <FloatingLabel
      controlId="floatingSearchInput2"
      className="small"
      label="Search here..."
    >
      <Form.Control
        type="search"
        placeholder="Search here..."
        className="small"
        value={value}
        onChange={onChange}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          boxShadow: "none",
        }}
        required
      />
    </FloatingLabel>
  );
};

export default ComponentSearch;
