import React from "react";
import html2canvas from "html2canvas";
import {
  faCircleInfo,
  faDownload,
  faEdit,
  faLocationDot,
  faPhone,
  faShare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./storeItem.css";
import { Button, Card, Spinner } from "react-bootstrap";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
const StoreItem = ({ store, className, handleEdit, handleDelete }) => {
  const [showDownloadWindow, setShowDownloadWindow] = useState(false);
  const qrRef = useRef(null);

  const handleDownloadScreen = async (e, fileName) => {
    e.preventDefault();
    setShowDownloadWindow(!showDownloadWindow);
    setTimeout(() => download(fileName), 1000);
  };

  const download = async (fileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = image;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setShowDownloadWindow(false);
  };

  const handelCopyStoreURL = (e, url) => {
    e.preventDefault();
    navigator.clipboard.writeText(url);
    toast.success("The store url is copied to your clipboard");
  };

  return (
    <>
      {showDownloadWindow && (
        <div className="qrDownloadWindow d-flex flex-column justify-content-center align-items-center">
          <div ref={qrRef}>
            <QRCodeSVG
              size={280}
              bgColor="#fff"
              fgColor="#662d91"
              value={process.env.REACT_APP_QR_CODE_URL.concat(
                "store/",
                store.slug
              )}
            />
          </div>
          <div className="mt-3 mb-1">Downloading</div>
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Card
        className={`store-item-cover w-100 shadow d-flex zoom-hover ${className}`}
      >
        <Card.Img
          variant="top"
          src={
            store.image_path
              ? process.env.REACT_APP_API_BASE_URL.concat(store.image_path)
              : "../images/store/default.jpg"
          }
        />
        <Card.Body className="d-flex flex-column align-items-center">
          <Card.Title className="text-primary text-uppercase fw-bold">
            {store.name}
          </Card.Title>
          <ul className="w-100 mt-4 list-unstyled text-secondary">
            <li className="d-flex flex-row align-items-center mb-3">
              <FontAwesomeIcon icon={faPhone} className="me-3" />
              <a
                href={`tel:${store.contact_number}`}
                className="text-secondary"
              >
                {store.contact_number}
              </a>
            </li>
            <li className="d-flex flex-row align-items-center mb-3">
              <FontAwesomeIcon icon={faLocationDot} className="me-3" />
              {store.address}
            </li>
            {store.other_notes && (
              <li className="d-flex flex-row align-items-center">
                <FontAwesomeIcon icon={faCircleInfo} className="me-3" />
                {store.other_notes}
              </li>
            )}
          </ul>
        </Card.Body>
        <Card.Footer className="w-100 mt-3 bg-white border-0">
          <div className="d-flex flex-row align-items-center">
            <Button
              size="sm"
              variant="warning"
              className="m-1"
              onClick={(e) => {
                handleEdit(e, store);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              size="sm"
              variant="danger"
              className="m-1"
              onClick={(e) => handleDelete(e, store.id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Button
              size="sm"
              className="ms-auto"
              variant="warning"
              title="Download QR Code"
              onClick={(e) => handleDownloadScreen(e, store.name)}
            >
              <FontAwesomeIcon icon={faDownload} className="me-1" /> QR Code
            </Button>
            <Button
              size="sm"
              className="ms-1"
              variant="dark"
              title="Copy and share"
            >
              <FontAwesomeIcon
                icon={faShare}
                size="sm"
                onClick={(e) =>
                  handelCopyStoreURL(
                    e,
                    process.env.REACT_APP_QR_CODE_URL.concat(
                      "store/",
                      store.slug
                    )
                  )
                }
              />
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
};

export default StoreItem;
