import React from "react";

const BrandLogo = () => {
  return (
    <div className="brand-logo">
      <img
        src="/images/logo.png"
        alt="logo"
        className="w-100 d-block mx-auto"
        style={{ maxWidth: 200 }}
      />
    </div>
  );
};

export default BrandLogo;
