import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Badge, Button, Tab, Table, Tabs } from "react-bootstrap";
import AppPagination from "../../../../components/AppPagination/AppPagination";
import CollapseCustomButton from "../../../../components/collapse-custom-button/CollapseCustomButton";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const Customers = () => {
  const [isLoadin, setisLoadin] = useState(true);
  const [customersData, setCustomersData] = useState(undefined);
  const getCustomersList = async (pageNumber) => {
    try {
      setisLoadin(true);
      let data = await axios.get("/api/list-users", {
        params: { page: pageNumber },
      });
      if (data?.data?.data) setCustomersData(data.data.data);
      setisLoadin(false);
    } catch (error) {
      setisLoadin(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomersList();
    return () => {
      setCustomersData(undefined);
      setisLoadin(true);
    };
  }, []);

  return isLoadin ? (
    "loading data..."
  ) : customersData?.data ? (
    <>
      <Accordion>
        <Table className="small" responsive striped hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Plan</th>
              <th>Purchased date</th>
              <th>Expiry date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {customersData.data.map((data, index) => (
              <Fragment key={index}>
                <tr>
                  <td>{(customersData?.from ?? 1) + index}</td>
                  <td>{data.name}</td>
                  <td>{data.email ?? "-"}</td>
                  <td>{data.contact_number ?? "-"}</td>
                  <td>
                    {data?.active_plan?.plan ? (
                      <Badge
                        bg={
                          data.active_plan.plan === "pro"
                            ? "success"
                            : "primary"
                        }
                        className="text-capitalize"
                      >
                        {data.active_plan.plan}
                      </Badge>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{data?.active_plan?.purchased_date ?? "-"}</td>
                  <td>{data?.active_plan?.expiry_date ?? "-"}</td>
                  <td>
                    {data?.active_plan?.expiry_date ? (
                      data.active_plan.package_is_active ? (
                        <Badge bg="success">Active</Badge>
                      ) : (
                        <Badge bg="danger">Expired</Badge>
                      )
                    ) : (
                      <Badge bg="warning">Not found</Badge>
                    )}
                  </td>
                  <td>
                    <CollapseCustomButton eventKey={index} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={9} className="px-0 py-0">
                    <Accordion.Collapse eventKey={index}>
                      <Tabs
                        id={"customer-details-tabs-" + index}
                        className="mb-3"
                        defaultActiveKey="stores"
                      >
                        <Tab
                          eventKey="stores"
                          title="Stores"
                          className="p-3 bg-light"
                        >
                          <Table className="small" responsive striped>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Slug</th>
                                <th>Currency</th>
                                <th>Contact Number</th>
                                <th>Address</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(data?.stores) &&
                              data.stores.length > 0 ? (
                                data.stores.map((store, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{store?.name ?? "-"}</td>
                                    <td>{store?.slug ?? "-"}</td>
                                    <td>{store?.currency ?? "-"}</td>
                                    <td>{store?.contact_number ?? "-"}</td>
                                    <td>
                                      <address>{store?.address ?? "-"}</address>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <Button
                                          as={Link}
                                          target="_blank"
                                          to={"/store/" + store?.slug}
                                          size="sm"
                                          className="d-flex flex-row gap-1 align-items-center"
                                        >
                                          <FaEye size={13} />{" "}
                                          <small>View Store</small>
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6} className="fw-bold">
                                    No results found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </Tab>
                      </Tabs>
                    </Accordion.Collapse>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
        <AppPagination data={customersData} onClick={getCustomersList} />
      </Accordion>
    </>
  ) : (
    "Something went wrong"
  );
};

export default Customers;
