import { faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import StoreItem from "../../components/storeItem/StoreItem";
import { ItemContext } from "../../contexts/ItemContenxt";
import StoreForm from "./StoreForm";
import ComponentSearch from "../../components/componentSearch/ComponentSearch";
import { LoginContext } from "../../contexts/LoginContext";
import LoadingUI from "../../components/LoadingUI/LoadingUI";

const Stores = () => {
  let { user, userDispatch } = useContext(LoginContext);
  let { items, setItems } = useContext(ItemContext);
  let { stores } = items;
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [deleteItem, setDeleteItem] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [show, setShow] = useState(false);

  const handleModal = () => {
    setShow(!show);
    setEditItem({});
    setDeleteItem(null);
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    if (item) {
      setEditItem(item);
      setShow(true);
    }
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (id) {
      setDeleteItem(id);
      setShow(true);
    }
  };

  const handleSearch = (e) => {
    let query = e.target.value;
    setSearchKey(query);
    setSearchResult(
      stores.filter(
        (storeItem) =>
          storeItem.name.toLowerCase().indexOf(query.toLowerCase()) > -1
      )
    );
  };

  const getStores = () => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get("/api/store")
        .then((response) => {
          setLoadingCompleted(true);
          const { data } = response;
          data.data &&
            setItems({
              ...items,
              stores: data.data,
            });
        })
        .catch((error) => {
          setLoadingCompleted(true);
          if (error?.response?.data?.plan_expired) {
            user.plan_expired = true;
            userDispatch({
              type: "UPDATE",
              payload: user,
            });
          }
          console.log(error);
        });
    });
  };

  useEffect(() => {
    if (!loadingCompleted) {
      getStores();
    }
  }, [loadingCompleted]);

  if (!loadingCompleted) return <LoadingUI message={"Looking for stores..."} />;

  return (
    <Container className="py-4">
      <StoreForm
        show={show}
        editItem={editItem}
        deleteItem={deleteItem}
        handleModal={handleModal}
      />
      <Card className="mb-3">
        <Card.Header className="d-flex flex-row justify-content-between align-items-center">
          <span className="h5 m-0">All Stores</span>{" "}
          <Button variant="dark" onClick={handleModal}>
            <FontAwesomeIcon icon={faStore} /> Add Store
          </Button>
        </Card.Header>
      </Card>
      <Row>
        {stores && stores.length > 0 ? (
          <>
            <Col sm={12} className="d-flex justify-content-end mb-3">
              <ComponentSearch value={searchKey} onChange={handleSearch} />
            </Col>
            {searchKey.length > 0 && searchResult.length < 1 ? (
              <Col sm={12}>
                <Alert variant="warning">No result found!</Alert>
              </Col>
            ) : null}
            {[...(searchKey.length > 0 ? searchResult : stores)].map(
              (store, index) => (
                <Col sm={12} md={6} lg={4} key={index} className="d-flex">
                  <Link
                    to={`/dashboard/categories/${store.id}`}
                    className="d-flex w-100"
                  >
                    <StoreItem
                      store={store}
                      className="mb-3 d-flex"
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  </Link>
                </Col>
              )
            )}
          </>
        ) : !loadingCompleted ? (
          <LoadingUI message={"Looking for stores..."} />
        ) : (
          <Col>
            <Alert variant="warning">No stores found!</Alert>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Stores;
